.tp-form-label {
  font-size: 12px !important;
  font-family: 'Roboto' !important;
  font-weight: 500 !important;
  color: #8c99a9;
  margin-left: 5px;
  //text-transform: uppercase;
  letter-spacing: 2px;
  padding-top: 10px;
}

.tp-form-label-no-padding {
  font-size: 12px !important;
  font-family: 'Roboto' !important;
  font-weight: 500 !important;
  color: #8c99a9;
  letter-spacing: 2px;
}

.tp-form-input {
  background-color: #f9f9fa;
  //background-color: white !important;
  border: 1px solid rgb(213, 218, 223);
  resize: none;
}

.tp-form-input-no-border {
  border: none;
  background-color: #f9f9fa;
  resize: none;
}

.tp-form-input-no-border .is-invalid {
  border: 5px solid red !important;
  background-color: #f9f9fa;
  resize: none;
}

// INverted Input with White background when borderig background is dark
.tp-form-input-invert {
  background-color: white !important;
  //background-color: white !important;
  border: 1px solid rgb(213, 218, 223);
  resize: none;
}

.tp-form-input-group {
  background-color: #f9f9fa !important;
  border: 1px solid rgb(213, 218, 223);
  border-radius: 4px;
}

.tp-form-select {
  border-radius: 4px;
}

.tp-form-input::placeholder {
  color: #b0b5b9 !important;
  opacity: 0.8 !important;
  font-style: italic;
}

.tp-form-input-invert::placeholder {
  color: #b0b5b9 !important;
  opacity: 0.8 !important;
  font-style: italic;
}
