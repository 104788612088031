.x-container {
  height: 100vh;
  width: 100vw;
  background-color: aliceblue;
}

.x-wrapper {
  display: grid;
  gap: 2px;
  grid-template-columns: 150px minmax(200px, auto) 150px 150px;
  grid-template-rows: auto;
  background-color: aqua;
  overflow-wrap: break-word;
}

.x-wrap {
  grid-column: span;
}

.x-info {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-column-end: 4;
}
