@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.red-text {
  color: red;
}

.grey-text {
  color: grey;
}
.blue-text {
  color: blue;
}
.orange-text {
  color: orange;
}
.green-text {
  color: green;
}
.bold-text {
  font-weight: 700;
}

.verticalWrapper {
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: auto;
}

/****************************
 *** BOOTSTRAP OVERRIDES ****
 ****************************/
.tp-body {
  overflow: auto !important;
}
// .card {
//   font-weight: 400;
//   border: 0;
//   box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
// }

// .card-body {
//   padding: 6px;
// }

.navbar {
  font-weight: 400;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.blue-gradient {
  background: linear-gradient(40deg, #45cafc, #303f9f) !important;
}

.light-gradient {
  background: linear-gradient(40deg, white, whitesmoke) !important;
}

.blue-gradientX {
  background: linear-gradient(40deg, #c6dde6, #a7c7ec) !important;
}

.form-check {
  padding-left: 5px;
}

/******************
 *** ADMIN CARD ***
 ******************/
.tp-admin-card .admin-up .fa,
.tp-admin-card .admin-up .far {
  padding: 1.7rem;
  font-size: 2rem;
  color: #fff;
  text-align: left;
  border-radius: 3px;
}
.tp-admin-card .admin-up .fa,
.z-depth-2 {
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.tp-admin-card .admin-up .data {
  float: right;
  margin-top: 2rem;
  text-align: right;
}
.tp-admin-card .admin-up {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: -20px;
}

/**********************
 ***** TRUSTPOINT *****
 **********************/
.tp-input,
.tp-input-inline,
.tp-textarea {
  background-color: aliceblue;
  border: 1px solid rgb(213, 218, 223);
  resize: none;
}

.tp-label {
  font-size: 12px !important;
  font-family: 'Roboto' !important;
  font-weight: 500 !important;
  color: #8c99a9;
  margin-left: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Styling For RDT Column Headings */
// .rdt_TableCol {
//   font-size: 11px !important;
//   font-family: 'Roboto' !important;
//   font-weight: 600 !important;
//   text-transform: uppercase;
//   letter-spacing: 2px;
//   margin-bottom: 5px;
//   margin-top: 3px;
// }

/* APPLY ROUNDED CORNERS TO RDT TABLE */
// .rdt_TableHeadRow {
//   border-radius: 5px;
//   border-spacing: 0;
// }

.tp-modal-wrapper {
  margin-left: 25px;
  margin-right: 15px;
}

.tp-form-text {
  padding: 15px;
}
.md-form {
  margin-top: 0px;
  padding-top: 0px;
}

.caret {
  padding-right: 4px !important;
}

/* Provide Hover affect to any element with tp-hover class */
.tp-hover:hover {
  filter: brightness(75%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.tp-hover-help:hover {
  filter: brightness(75%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  cursor: help;
}

/*** ENABLE STANDARD CHECKBOX CSS WHEN CLASS OF PARENT IS standard-checkbox ***/
.standard-checkbox input[type='checkbox'] {
  background-color: yellow;
  pointer-events: all !important;
  opacity: initial !important;
  margin: 5px 5px 0 0 !important;
}

/* FIXES FOR REACT_DATEPICKER WIDTH */
.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.admin-icon {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.grad-blue {
  background-image: linear-gradient(to right, #45c5f9, #3041a0);
}

/* tp-appwrapper wraps around the entire application */
.tp-appwrapper {
  margin-right: 0px;
  margin-left: 0px;
  overflow-x: hidden; /* Hide horizonal scrollbar */
}

/* Main Layout Format (Allows for Navbar at top) */
.tp-main-layout {
  margin-top: 55px;
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

/* tp-body wraps around all elements below navbar */
/* .tp-body {
  padding: 2px;
  overflow-y: auto;
} */

/* tp-page wraps all component pages (except dashboard) */
.tp-page {
  padding: 0px;
  padding-left: 4px;
  padding-right: 4px;
  margin: 0px;
  user-select: none !important;
}

/* tp-dashboard applies around dashboard items only */
.tp-dashboard {
  padding-top: 5px;
}

/* CRUD BUTTON STYLING */
.tp-btn-outer {
  margin-left: 10px;
  margin-right: 10px;
}

.tp-btn {
  padding: 10px !important;
  height: 50px;
}

.nav-item {
  padding-left: 5px;
}

/* DivTable.com */
.divTable {
  display: table;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  border: none;
  display: table-cell;
  padding: 10px 10px;
  vertical-align: middle;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}

.tp-use-remaining-height {
  height: calc(100vh - 90px);
}

.flex-card-full-height {
  height: calc(100vh - 90px);
}

.tp-editor-card-full-height {
  height: calc(100vh - 202px);
}

.tp-blue {
  font-weight: 800 !important;
  color: blue;
}

.tp-purple {
  font-weight: 800 !important;
  color: purple;
}

.tp-align-middle {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.tp-card-heading {
  color: black;
  font-weight: 500;
  font-size: x-large;
  display: flex;
  align-items: center;
}

.tp-card-heading-light {
  color: whitesmoke;
  font-weight: 500;
  font-size: x-large;
  display: flex;
  align-items: center;
}

.flex-table-button {
  border: 4px solid white;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-table-button:hover {
  border-color: lightgray;
}

.flex-table-icon {
  color: white;
}

.flex-spinner {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 50%;
  display: none;
}

.tp-noshaddow {
  box-shadow: none;
}

.testheight {
  height: calc(100% - 90px);
}

.tp-light-gradient {
  background-image: linear-gradient(to right, #cbddf3 0%, #89c2f7 100%);
}

.bg-transparent {
  background-color: transparent !important;
}

.tp-crud-modal {
  top: 40px;
  outline: none !important;
  border: 0 !important;
  /* border: 2px solid transparent; */
}

#crud-body {
  padding: 6px;
}

.form-check-input {
  margin-left: 0px !important;
}

.form-check-label {
  margin-left: 24px !important;
}

.flex-dismiss {
  margin-top: -5px;
  margin-right: -5px;
}

.tp-modal-heading {
  color: FCFCFC;
  font-weight: 500;
  font-size: x-large;
  display: flex;
  align-items: center;
}

.tp-link {
  color: yellow;
}

.tp-link:hover {
  color: orange;
}
.testxx {
  grid-area: HNAV-start / MAIN-start;
}

.form-header {
  font-weight: 500;
  background-color: lightgray;
  opacity: 0.75;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.form-header-condensed {
  font-weight: 500;
  background-color: lightgray;
  opacity: 0.75;
  border-radius: 5px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.form-header-clear {
  background-color: transparent;
}

.form-header-clear {
  font-weight: 500;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.form-total {
  font-weight: 500;
  background-color: lightgray;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.form-total-clear {
  font-weight: 500;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.form-auto {
  width: 1px;
  white-space: nowrap;
}

.report-content {
  min-height: '1000px';
}

// PREVENT REPORT VIEWER X-OVERFLOW
.rpv-default-layout__body {
  overflow-x: hidden !important;
}

// .chakra-alert {
//   display: flex !important;
// }

.tp-nav-button-expanded::before {
  margin-left: -10px;
}

body {
  user-select: none;
}

//FIX FOR TINY MC DROPDOWN
.tox-tinymce-aux {
  z-index: 999999 !important;
}

//HIDE OVERFLOW ON PLACEMENT OF MODAL
.chakra-modal__content-container {
  overflow: hidden !important;
}
